<template>
  <div class="home">
    <!-- <map-card></map-card> -->
  </div>
</template>

<script>
// import { message } from 'ant-design-vue'
import MapCard from './MapCard.vue'
export default {
  name: 'home',
  components: {
    MapCard
  },
  mounted() {
    // message.info('首页')
  },
  methods: {
    onChange() {
      
    }
  }
}
</script>
