<template>
  <a-card title="百度地图" :bordered="false" style="width: 500px" :body-style="{padding: 0}">
    <div class="map-container" ref="mapContainer"></div>
  </a-card>
</template>
<script>
import BMapGL from 'BMapGL'
export default {
  name: 'bmap-card',
  mounted() {
    this.$nextTick(() => {
      this.init();
    })
  },
  methods: {
    init() {
      var map = new BMapGL.Map(this.$refs.mapContainer);
      var point = new BMapGL.Point(116.404, 39.915);
      map.centerAndZoom(point, 15);
      setTimeout(function(){  
        map.panTo(new BMapGL.Point(116.409, 39.918));    
      }, 2000);
      map.enableScrollWheelZoom(true);
    },
  }
}
</script>
<style lang="scss" scoped>
.map-container {
  height: 400px;
}
</style>